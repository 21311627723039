const messages = {
  da: {
    reactMuiTables: {
      selected: 'Udvalgte',
      filterhide: 'Skjul filter',
      filtershow: 'Vis filter',
      refresh: 'Nulstil',
      filter: {
        actions: {
          search: 'Søge',
          resetFilter: 'Nulstil filter',
          addCondition: 'Tilføj betingelse',
        },
      },
      standardOperators: {
        equals: 'lige med',
        contains: 'indeholder',
        between: {
          label: 'mellem',
          from: 'af',
          to: 'Til',
        },
        oneOf: 'en af',
      },
      labelRowsPerPage: 'Linjer pr. side',
    },
  },
};
export default messages;
