const messages = {
  da: {
    com: {
      components: {
        headerCartButton: {
          cartButton: 'Min kurv',
        },
      },
      productDetail: {
        parameters: {
          title: 'Indstillinger',
        },
      },
      productAvailability: {
        format: {
          available: 'På lager',
          moreThanX: 'Mere end {min} varer på lager',
          exactCount: '{value} varer på lager',
          notAvailable: 'Ikke tilgængelig',
        },
      },
      imageGallery: {
        thumbnailsMore: '+ {number} mere',
      },
      relatedProducts: {
        title: 'Kunderne satte også pris på',
      },
      productAccessories: {
        title: 'tilbehør',
      },
      productDetailImageGallery: {
        noImage: 'Intet foto',
      },
      productAvailabilityFieldPreviewWithPopup: {
        button: 'Leveringsmuligheder',
      },
    },
  },
};
export default messages;
