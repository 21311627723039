const messages = {
  da: {
    shoppingCart: {
      actions: {
        addToCart: 'Læg i kurv',
        buy: 'Købe',
        order: 'Bestille',
        remove: 'Slet',
      },
      cart: {
        total: {
          label: 'Total',
          helperText: '',
          helperTextPreview: '',
        },
        totalItems: {
          label: 'Total',
          helperText: '',
          helperTextPreview: '',
        },
        totalShipping: {
          label: 'Fragt',
          helperText: '',
          helperTextPreview: '',
        },
        totalPayment: {
          label: 'Ordretotal',
          helperText: '',
          helperTextPreview: '',
        },
        totalTaxes: {
          label: 'Moms',
          helperText: '',
          helperTextPreview: '',
        },
        resetCart: 'Nulstil din indkøbskurv',
      },
      messages: {
        confirmResetCart: 'Er du sikker på, at du vil slette indholdet af kurven?',
        giftClaim: 'Du kan få {quantity} stykker af {productName} som gave.',
        cartManipulations: {
          allRemoved: 'Alle varer er blevet fjernet fra indkøbskurven.',
          itemAdded: '{number, plural, one {item added to cart} other {items added to cart}}.',
          itemRemoved: '{number, plural, one {Vare fjernet fra indkøbsvogn} other {Varer fjernet fra indkøbsvogn}}.',
          quantityChaged: 'Den købte mængde er ændret.',
        },
      },
      components: {
        appBartActionCart: 'Kurv',
        quantityField: {
          states: {
            quantityRounded: 'Mængden er afrundet til nærmeste pakkestørrelse.',
          },
        },
        cartAsidePreview: {
          cartEmpty: 'Din indkøbskurv er tom',
        },
        cartAfterAddModal: {
          continueShopping: 'fortsætte med at handle',
          goToCart: 'Gå til indkøbskurven',
          title: 'Produkter er blevet tilføjet til indkøbskurven',
          pcs: 'stk',
        },
      },
    },
  },
};
export default messages;
