const messages = {
  da: {
    sprinxFormsValidation: {
      required: 'Obligatorisk felt!',
      string: 'Skal være en tekststreng!',
      stringEmpty: 'Må ikke være tom',
      stringMin: 'Længden skal være større end eller lig med {expected} tegn!',
      stringMax: 'Længden skal være mindre end eller lig med {expected} tegn!',
      stringLength: 'Længden skal være {expected} tegn!',
      stringPattern: 'Passer ikke til den påkrævede model!',
      stringContains: "Skal inkludere teksten '{expected} '!",
      stringEnum: 'Matcher ikke nogen af ​​de tilladte værdier!',
      stringNumeric: 'Det må være et tal!',
      number: 'Det skal være et tal!',
      numberMin: 'Skal være større end eller lig med {expected}!',
      numberMax: 'Skal være mindre end eller lig med {expected}!',
      numberEqual: 'Skal være det samme som {expected}!',
      numberNotEqual: 'Må ikke være det samme som {expected}!',
      numberInteger: 'Skal være et heltal!',
      numberPositive: 'Tallet skal være positivt!',
      numberNegative: 'Tallet skal være negativt!',
      array: 'Det skal være en liste!',
      arrayEmpty: 'Dette er ikke nødvendigvis en tom liste!',
      arrayMin:
        'Skal være mindst {number, plural, one {1 produkt} few {{number} produkter} other {{number} produkter}}!',
      arrayMax:
        'Kan ikke indeholde mere end {number, plural, one {1 produkt} few {{number} produkter} other {{number} produkter}}!',
      arrayLength:
        'Skal indeholde præcis {expected, plural, one {1 produkt} few {{number} produkter} other {{number} produkter}}!',
      arrayContains: "Skal indeholde produktet '{expected}'!",
      arrayEnum: "Værdien '{expected}' matcher ikke nogen af ​​de tilladte værdier!",
      boolean: 'Skal være ja"',
      function: 'Skal være en funktion!',
      date: 'Det skal være en date!',
      dateMin: 'Skal være større end eller lig med {expected}!',
      dateMax: 'Skal være mindre end eller lig med {expected}!',
      forbidden: 'Forbudt!',
      email: 'E-mail er korrekt!',
      url: 'Ingen gyldig URL!',
      object: 'Skal være objekttype!',
      arrayReducedEquals: 'Samlet værdi stemmer ikke overens med {expected}!',
      phoneNumber: "Telefonnummeret skal være i et gyldigt internationalt format! '+ XXX XXX XXX XXX '!",
      dateGt: 'Datoen skal være større end {expected}!',
      dateGte: 'Datoen skal være større end eller lig med {expected}!',
      dateLt: 'Datoen skal være mindre end {expected}!',
      dateLte: 'Datoen skal være mindre end eller lig med {expected}!',
    },
  },
};
export default messages;
