const messages = {
  da: {
    app: {
      about: 'Om ansøgningen',
      actions: {
        add: 'Tilføje',
        addNamed: 'Tilføj {name}',
        remove: 'SLET',
        removeNamed: 'Slet {name}',
        removeConfirmation: 'Er du sikker på at du vil slette?',
        more: 'Mere',
        less: 'Mindre',
        download: 'Hent',
        save: 'Opdater profil',
        cancel: 'Afbestille',
        close: 'At validere',
        moreFormFields: 'Flere felter',
        export: 'Eksport',
        edit: 'Redigere',
        createdSuccessMessage: 'Oprettet med succes.',
        savedSuccessMessage: 'Registreret med succes.',
        deletedSuccessMessage: 'Slettet.',
        detail: 'Detailhandel',
        next: 'Forfølge',
        back: 'Feedback',
        start: 'Starten',
        whatNext: 'Hvad sker der efter...',
        confirm: 'Bekræfte',
        confirmRemove: 'Er du sikker på at du vil slette?',
        search: 'Hvad kan vi hjælpe med i dag?',
        userProfile: 'Brugerprofil',
        tenantProfile: 'Din CRM-profil',
        imports: 'Importere',
        fileImports: 'Importer filer',
        settings: 'Indstillinger',
        logout: 'Log ud',
        login: 'Log på',
        filterList: 'Filterliste',
        excel: 'Excel',
        refresh: 'Opdater',
        clear: 'Klar',
        agree: 'jeg er enig',
        disagree: 'Jeg er ikke enig',
        makeCopy: 'lav en kopi',
        fullscreen: 'Vis i fuld skærm',
        fullscreenClose: 'Luk fuldskærmsvisning',
        copy: 'Kopi',
      },
      messages: {
        confirmRemove: 'Vil du slette produktet?',
        errorOccurred: 'Der opstod en fejl',
        createdSuccess: 'Skabelsen lykkedes.',
        savedSuccess: 'Sikkerhedskopieringen var vellykket.',
        deletedSuccess: 'Sletningen lykkedes.',
        uploadSuccess: 'Filen blev uploadet.',
        uploadError: 'Fejl ved download af fil.',
        uploadInProgress: 'Hent...',
        uploadedFiles:
          '{number, plural, one {1 fil downloadet} few {{number} filer downloadet} other {{number} filer er blevet downloadet}}',
      },
      error: 'Fejl!',
      validations: {
        isRequired: 'Det obligatoriske {field} felt.',
        minLengthIsRequired:
          'Mindst {number, plural, one {1 item} few {{number} items} other {{number} produkter}} er påkrævet.',
        minValue: 'Den mindst tilladte værdi er {value}.',
        maxValue: 'Den maksimalt tilladte værdi er {value}.',
        passwordMatchError: 'Kodeordene er ikke ens',
        invalidEmail: 'Ugyldig email.',
        invalidPhoneNumber: "Telefonnummeret skal være i et gyldigt internationalt format! '+ XXX XXX XXX XXX '!",
        notValidNumber: 'Ugyldigt nummer',
        invalidUrl: 'Ugyldig URL-adresse.',
        positive: 'Tallet skal have en positiv værdi.',
        negative: 'Tallet skal have en negativ værdi.',
      },
      login: {
        title: 'Log ind på Springx CRM',
        username: 'ID',
        password: 'Adgangskode',
        loginButton: 'Log på',
        error: 'Fejl',
        pleaseRelogin: 'Du er ikke forbundet. Prøv venligst at logge ind igen.',
      },
      days: '{count, plural, one {1 dag} two {{formattedCount} dage} other {{formattedCount} dage}}',
      overdue: 'zamuda {count, plural, one {1 dag} two {{formattedCount} dage} other {{formattedCount} dage}}',
      newItem: 'Ny',
      anonymizing: {
        confirm: {
          title: 'Bekræfte',
          content: 'Er du sikker på at anonymisere personlige data?',
        },
        button: 'Anonymisering af persondata',
      },
      appMenu: {
        discover: 'Opdage',
      },
      missingType: 'Angiv venligst {typeName} i applikationsadministrationen først.',
      missingTypeForEntityType: 'Indstil først {typeName} for {forEntityTypeName} i applikationsadministration.',
      emptyScoringBlog: 'Moduler, hvis indhold eller indstillinger vises på denne side, aktiveres ikke.',
    },
    components: {
      buttons: {
        showOnMap: 'Vis på kort',
      },
      filters: {
        filterButtonSubmit: 'Søge',
        filterFieldName: '{name} ({conditionType})',
        addCondition: 'Tilføj betingelse',
        resetFilter: 'Nulstil filter',
        operators: {
          contains: {
            0: 'indeholder',
            _: 'indeholder',
          },
          containsexact: {
            0: 'indeholder præcis',
            _: 'indeholder præcis',
          },
          notcontains: {
            0: 'indeholder ikke',
            _: 'indeholder ikke',
          },
          betweennumber: {
            0: 'af',
            1: 'Til',
            _: 'mellem',
          },
          betweendate: {
            0: 'af',
            1: 'Til',
            _: 'mellem',
          },
          equal: {
            0: 'er den samme',
            _: 'er den samme',
          },
        },
      },
      paginationList: {
        itemsTotal: 'Samlede produkter: {number}',
        nextPage: '{number} følgende produkter',
        emptyMessage: 'Ingen produkter fundet.',
      },
      selects: {
        emptyOption: 'Ingen',
        emptyMessage: 'Ingen produkter fundet.',
      },
      tables: {
        labelRowsPerPage: 'En streg på siden',
        sortBy: 'Sorter efter {field}',
        recordsChanged: 'Nogle poster er tilføjet eller ændret.',
      },
      textField: {
        errorMsgWrapper: '',
      },
      dateField: {
        invalidDateFormat: 'Uregelmæssigt datoformat.',
        invalidDate: 'Ukendt',
        maxDateMessage: 'Datoen kan ikke være større end den maksimale dato',
        minDateMessage: 'Datoen må ikke være mindre end minimumsdatoen',
        today: 'I dag',
      },
      detailNavigation: {
        scoringblog: 'Bedømmelsesblog',
        card: 'Kort',
        relations: 'Være',
      },
      manipulationInfo: {
        created: 'Etableret',
        updated: 'Ændret',
        removed: 'Slettet',
      },
    },
  },
};
export default messages;
