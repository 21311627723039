const messages = {
  da: {
    parameters: {
      yes: 'ET',
    },
    com: {
      phase: {
        label: 'Mærkning',
      },
      product: {
        ean: {
          label: 'EAN',
          helperText: '',
          helperTextPreview: '',
        },
        sku: {
          label: 'WARENNR',
          helperText: '',
          helperTextPreview: '',
        },
        manufacturer: {
          label: 'Maker',
          helperText: '',
          helperTextPreview: '',
        },
        pn: {
          label: 'Arbejdsnummer',
          helperText: '',
          helperTextPreview: '',
        },
        warranty: {
          label: 'Garanti',
          months: 'måneder',
        },
      },
      totalAmount: {
        label: 'Total',
        helperText: '',
      },
    },
    imageGallery: {
      thumbnailsMore: '+ {number} mere',
    },
    orders: {
      actions: {
        all: 'Alle ordrer',
      },
      myOrdersScoringBlog: {
        title: 'Mine Ordrer',
      },
      ordersList: 'Ordre:% s',
      phase: {
        1: 'Modtaget',
        '01-new': 'Modtaget',
        10: 'Det er forudset',
        20: 'venter på betaling',
        30: 'Betalt',
        98: 'koloniserede',
        99: 'Aflyst',
      },
      listPage: {
        table: {
          columns: {
            packageId: 'Pakkenummer',
            phase: 'Status',
            paid: 'Betaling',
            total: 'Total ekskl. moms',
            totalWithTax: 'Total pris',
          },
        },
      },
    },
    baskets: {
      cartSinglePage: {
        title: 'INDKØBSKURV',
        buyButton: 'Bestille',
        emptyBasket: 'Kurven er tom',
        buyThere: 'Allerede kunde?',
        login: 'Jeg vil gerne tjekke ind',
        notFill: 'Leveringsdetaljer ikke oplyst',
        fill: 'Udfyld oplysningerne',
        change: 'Rediger data',
        popupButton: 'Tilføj til kort',
        payment: {
          title: 'BETALINGSMETODE',
          free: 'Gratis',
          changePaymentBtn: 'SKIFT BETALINGSMÅDE',
        },
        shipping: {
          title: 'LEVERINGSMETODE',
          free: 'Gratis',
          changeShippingBtn: 'VÆLG MIN LEVERING',
          popup: {
            title: 'Information',
            text: 'Levering til din adresse med kurer {service}',
          },
        },
      },
      actions: {
        all: 'Alle kurve',
      },
      myBasketsScoringBlog: {
        title: 'Gemte kurve',
      },
      cartSummaryParameters: {
        caption: 'Produktkode',
      },
    },
    productDetailSpagettyNavigation: {
      description: 'BESKRIVELSE',
      parameters: 'SPECIFIKATIONER',
      package: 'TILBEHØR',
      fotoAndVideo: 'Billede og video',
      alternatives: 'ALTERNATIVER',
      alternativesText: 'Ingen alternativer til det valgte produkt.',
      questions: 'SKRIV OS',
      productDescriptionNotAvailable: 'Beskrivelse ikke tilgængelig',
    },
    newsletter: {
      phone: '+386 1 82 82 054',
      subscribe: {
        title: 'Gå ikke glip af de særlige tilbud',
        inputText: 'E-mail',
        button: 'Abonner',
      },
    },
    globalNavigation: {
      home: 'Forside',
      shopping: 'Køb',
      orders: 'Bestille',
      shoppingLists: 'Indkøbslister',
      articles: 'Nyhed',
    },
    homePageWhy: {
      0: {
        title: 'Sikker betaling',
        content: 'Shop sikkert med de mest almindelige betalingsmetoder',
      },
      1: {
        title: 'Returnering & fejlkøb',
        content: 'Du har altid 14 dages fortrydelsesret, når du handler hos Digiexpert.dk som forbruger.',
      },
      2: {
        title: 'Levering inden 2-3 dage',
        content: 'Ordrer afgivet før kl. 13:30 sendes ofte samme hverdag.',
      },
      3: {
        title: 'Gratis Fragt',
        content: 'Fri fragt på udvalgte produkter. Gælder køb af mærkede varer.',
      },
      title: 'HVORFOR OS?',
    },
    startPage: {
      title: 'Hjemmeside',
      gotoShopping: 'Gå til shoppen',
    },
    shoppingCart: {
      actions: {
        buy: 'TILFØJ TIL KURV',
      },
      buyBtn: 'TILFØJ TIL KURV',
      alternatives: 'ALTERNATIVER',
      shipping: {
        changePoint: 'At skifte',
      },
      agreements: {
        termsRequired: 'Accepter venligst handelsbetingelserne.',
      },
      validation: {
        someProductNotAvailable: 'Nogle produkter er ikke på lager i tilstrækkelige mængder.',
      },
    },
    appCartSummaryProductParameters: {
      title: 'Warennr',
    },
    productListPage: {
      title: 'Katalog',
      orders: {
        recommended: 'Relevans',
        name: 'A-Z',
        priceAsc: 'Stigende pris',
        priceDesc: 'Faldende pris',
      },
    },
    orderStatuses: {
      storno: 'Annullering',
      synchronized: 'Sendes',
      waiting: 'Venter på synkronisering',
    },
    orderPreview: {
      items: 'Produkter',
    },
    cartRegisterForm: {
      title: 'Tilmeldingsblanket',
    },
    cartPayment: {
      title: 'BETALINGSMETODE',
    },
    cartPreSummaryPage: {
      title: 'Indkøbskurv oversigt',
      actions: {
        nextStep: 'Jeg accepterer og fortsætter',
      },
    },
    cartCompanyForm: {
      description: '',
    },
    cartDeliveryForm: {
      description: '',
    },
    productAvailabilityFieldPreviewWithPopup: {
      button: 'Leveringsmuligheder',
    },
    productDetailStockInfo: {
      available: '',
      external: 'Produktet er på vej til vores lager',
      notAvailable: 'Varer er midlertidigt utilgængelige',
      labels: {
        free: 'GRATIS FRAGT',
        showroom: 'UDSÆTTET I UDSTILLINGSRUMET',
      },
    },
    productGifts: {
      btnName: 'GAVE',
      giftValue: 'I værdien af',
    },
    homepage: {
      productOfMonth: 'UDVALGT TIL DIG',
      catalogFavorite: 'POPULÆRE KATEGORIER',
      hotTips: 'BEDST SÆLGER TIL EN FANTASTISK PRIS!',
      recommended: 'vores udvalg',
    },
    productQuestion: {
      firstName: 'Fornavn',
      lastName: 'Efternavn',
      email: 'E-mail',
      subject: '',
      question: 'Skriv din besked her..',
      gdpr: 'Jeg accepterer behandling af personoplysninger for at få svar på spørgsmålet.',
      gdprLink: 'GDPR link',
      button: 'Sende',
      subjectContent: 'Spørgsmål om produkt : {product}, kode : {sku}',
      title: 'Du skal acceptere behandlingen af ​​personoplysninger.',
      success: 'Skemaet er indsendt',
    },
    lastVisitedProducts: 'Senest viste produkter',
    productAlternativeProducts: 'Alternativer',
    productAccessoryProducts: 'tilbehør',
    productReplacementProducts: 'Tilføje',
    productAccessories: 'Tilføje',
    productReplacements: 'Udskiftning',
    appProductDetailTabs: {
      description: 'Beskrivelse',
      parameters: 'Indstillinger',
      package: 'Pakkens indhold',
      query: 'Et spørgsmål',
      media: 'Billede og video',
      alternatives: 'Alternativ',
      accessories: 'tilbehør',
      replacements: 'tilbehør',
      form: {
        enum: '!!!! ENUM VÆRDI',
      },
    },
    cartSummaryPage: {
      title: 'Opsummering før bestilling',
      actions: {
        continueShopping: 'Fortsæt dine indkøb',
        saveOrder: 'Opret en ordre',
      },
      expectedDeliveryDate: {
        label: 'Leveringsdato påkrævet',
        helperText: '',
        helperTextPreview: '',
      },
      noteForSeller: {
        label: 'Meddelelse til leveringsperson',
        helperText: '',
        helperTextPreview: '',
      },
      paymentGateway: {
        label: 'betalingsgateway',
        helperText: '',
        helperTextPreview: '',
      },
    },
    userProfile: {
      title: 'Min konto',
      content: 'Indhold',
      contactInfo: {
        newsletter: {
          title: 'Nyhedsbrev',
          content: 'Jeg vil gerne modtage nyheder fra Digiexpert.si',
        },
        title: 'Kontoinformationer',
      },
      orders: {
        title: 'Ordre historik',
      },
      invoices: {
        title: 'Mine konti',
      },
      myOrders: 'Orderer',
      myInvoices: 'Fakturaer',
      logout: 'Log ud',
      login: 'For at logge ind',
      favorites: {
        title: 'Ønskeliste',
      },
      changePassword: {
        title: 'Adgangskodeændring',
        fields: {
          login: 'ID',
          originalPassword: 'adgangskode',
          newPassword: 'ny adgangskode',
          confirmNewPassword: 'bekræft adgangskode',
        },
        button: 'Opdater adgangskode',
      },
    },
    orderSavedThankPage: {
      title: 'Tak for din ordre {orderNumber}',
      thankMessage: {
        email: 'info@digiexpert.dk',
        phoneNumber: '',
        template:
          'Tjek venligst din e-mail for fuldstændige ordredetaljer. Gem e-mailen til eget brug. Alle oplysninger vedrørende din ordre kan findes i afsnittet nedenfor.',
      },
      order: 'Ordrenummer',
      payment: 'Dagens betaling',
      cancelled: 'Aflyst',
      accepted: 'Modtaget',
      pending: 'Venter',
      paid: 'Betalt',
      actions: {
        back: 'Tilbage til hjemmesiden',
        detail: 'Ordreoversigt',
        continue: 'Fortsæt dine indkøb',
      },
      decline: 'Afvist',
    },
    shoppingLists: {
      title: 'Indkøbslister',
      actions: {
        saveAsShoppingList: 'Gem som indkøbsliste',
        importShoppingListToCart: 'Importere',
      },
    },
    searchResultPage: {
      search: 'Forskning',
      results: 'Søgeresultater',
    },
    homePageArticles: {
      title: 'Nyheder og Tilbud',
      button: 'Læs mere',
    },
    loginPage: {
      title: 'Log på',
      forgotPassword: 'Nulstille kodeord',
      email: 'E-mail',
      sendEmail: 'Send en e-mail',
      info: 'Du vil modtage en e-mail med et link til at ændre din adgangskode',
      password: 'Adgangskode',
      notSame: 'Adgangskoden passer ikke',
      newPassword: 'nyt kodeord',
      confirmPassword: 'Bekræft adgangskoden',
      changePassword: 'Adgangskodeændring',
    },
    productPrice: {
      withoutTax: 'uden moms',
    },
    productDetailPage: {
      availability: {
        dateRange: 'Bestil {today} og vi leverer inden for {minDays}-{maxDays} dage.',
        today: 'Bestil inden {time} vil din pakke blive sendt til dig i dag!',
        todayWord: 'I dag',
        tomorrow: 'Bestil {today} vil din pakke blive sendt til dig i morgen!',
        nextDateTime: '13:00',
        dayOfWeek: {
          message: 'Bestil {today}, og vi sender {day}.',
          1: 'Søndag',
          2: 'Mandag',
          3: 'Tirsdag',
          4: 'Onsdag',
          5: 'TORSDAG',
          6: 'Fredag',
          0: 'LØRDAG',
        },
      },
      allDescription: 'BESKRIVELSE',
      demoProduct: {
        text: 'KØB BILLIGERE for ',
      },
      productNote: {
        title: 'KØB BILLIGT for 2.150 kr',
        note: 'Note 2 - Yderligere beskrivelse af tilstand eller lignende produktinformation  hvorfor får den rabat og hvad er der på varerne…',
      },
      adornments: {
        notForSale: 'Ikke specifikt til salg',
        usedProduct: 'andet liv',
        freeGift: 'En gratis gave',
        freeShipping: 'Gratis fragt',
        eol: 'Lukket salg',
        demo: 'Demo produkt',
        sale: 'Salg',
      },
      disponibility: {
        primaryLessThan: 'FÅ PÅ LAGER',
        primaryMoreThan: 'PÅ LAGER {count}+',
        secondaryLessThan: 'PÅ LAGER på plukkelageret mindre end {count} enheder',
        secondaryMoreThan:
          'PÅ LAGER på klargøringslageret mere end {count, plural, zero {et stykke} other {{count} stykker}}',
        external: 'PÅ FJENLAGER',
        unavailable: 'IKKE PÅ LAGER',
        showroom: '(præsenteret i udstillingslokalet)',
        soldout: 'IKKE PÅ LAGER',
        'on-way': {
          d: '',
          m: 'Undervejs hæld {from, plural, one {minute} two {minute} other {minute}}',
          M: 'På vej til {from, plural, one {month} two {month} few {month} other {month}}',
          dRange: 'Undervejs hæld {to, plural, few {{from}-{to} days} other {{from}-{to} days}}',
          mRange: 'Il y a {from}-{to, plural, one {than} a few {who} other {who}}',
          MRange: 'På vej {from}-{to, plural, one {month} two {month} few {month} other {month}}',
          date: '{datum}',
        },
        time: 'På vej mere end {count, plural, zero {one piece} other {{count} pieces}}',
      },
    },
    comparePage: {
      title: 'Sammenligne',
      removeButton: 'Slet produkt',
      nothing: 'Ingen produkter at sammenligne',
    },
    filter: {
      reset: 'Nulstil filter',
      search: 'Søge',
    },
    orderTermsAndConditions: {
      select: {
        label: 'Bestillingsbetingelser',
        helperText: '',
        helperTextPreview: '',
      },
    },
    orderDetailPage: {
      actions: {
        import: 'Tilføj til kurv',
      },
    },
    invoiceBlogPreview: {
      title: 'Mine konti',
      button: 'Alle konti',
    },
    userProfilePage: {
      logout: 'Log ud',
      setting: 'Indstillinger',
      passwordChange: 'Adgangskodeændring',
    },
    appBarActionRegion: {
      changeRegion: 'Sprog',
      label: 'At validere',
    },
    subCategories: {
      title: 'Den bedste af vores kategorier',
    },
    aboutShoppingPage: {
      title: 'ALT OM KØBET',
      contact: 'KONTAKT',
      electronicClosureOfRevenue: 'ELEKTRONISK REGISTRERING AF INDKOMST',
      informationDuty: 'INFORMATIONSFORPLIGTELSER',
      processingOfPersonalData: 'BEHANDLING AF PERSONOPLYSNINGER',
      cashback: 'PENGE TILBAGE',
      delivery: 'LEVERING',
      availability: 'TILGÆNGELIGHED',
      methodOfPayment: 'BETALINGSMETODE',
      termsAndConditions: 'VILKÅR OG BETINGELSER',
      termsOfAppeal: 'APPELBETINGELSER',
      contractwWithdrawal: 'FORTRYDELSE FRA KONTRAKTEN',
    },
    customer: {
      viesValidation: {
        viesVerified: 'Dit skattenummer er tjekket priserne faktureres uden moms.',
        viesNotVerified: 'Dit skattenummer er ikke blevet bekræftet',
      },
    },
    cartCustomerForm: {
      dataForDelivery: 'KUNDEOPLYSNINGER',
      pleaseComplete: 'UDFYLD VENLIGST',
      closeButton: 'GODKEND',
    },
    cartPromoCodes: {
      title: 'PROMOKODE ANVEND',
      textFieldPlaceholder: 'PROMOKODE',
      textFieldHelperText: 'Det er kun muligt at indløse én promokode pr. ordre',
      applyPromoCodeButton: 'ANSØGE',
    },
    cookiesBar: {
      agreeBtn: 'Del',
      title: 'MEDDELELSE VEDRØRENDE COOKIES PÅ VORES HJEMMESIDE',
      text: 'Vores virksomhed bruger sine egne cookies (kaldet essentielle cookies) og tredjepartscookies på denne hjemmeside. På baggrund af disse filer og interesser tilpasser vi hjemmesiderne',
    },
    cartFormLogin: {
      btnSend: 'SEND',
      button: 'Log ind',
      title: 'LOG IND / OPRET EN KUNDEKONTO',
      forgottenPasswordBtn: 'Glemt adgangskode?',
      text1: 'Intet problem. Vi sender en mail til dig med et link, så du kan nulstille din adgangskode',
      text2: '',
      error: 'Forkert brugernavn eller kodeord.',
    },
    cartSummartSection: {
      title: 'BESTILLINGSRESUMÉ',
    },
    appCartSummarySubjectsList: {
      title: 'VARER',
      text: 'BESKRIVELSE',
    },
    newsletterText: {
      text1: 'Har du brug for råd?',
      text2: 'Kontakt os på hverdage fra 8:00 til 16:00.',
    },
    cartSummarySection: {
      labelWithoutVAT: '(uden afgifter)',
      priceLabel: 'PRIS',
      amount: 'ANTAL',
      totalPriceLabel: 'TOTAL PRIS',
      totalPrice: 'Total',
      submitButton: 'GODKEND OG BETAL',
    },
    cartSummarySubjectsListItemDirectPreview: {
      amountTitle: '',
    },
    cartSummaryListItem: {
      freeTitle: '',
      amountTitle: 'enheder',
    },
    sxCartNotes: {
      note: 'Det bruges ikke til at angive leveringsdatoen',
      addNote: 'Besked til os',
    },
    productDetail: {
      promoCode: {
        title: 'med promokode:',
        validTo: 'udløbsdato:',
      },
      productSendLink: {
        copyLinkBtn: 'Kopier linket',
        sendLinkByEmail: 'E-mail link',
      },
      productDetailBarInformation: {
        addToFavourites: 'Ønskeliste',
        compareProduct: 'Sammenlign',
        sendLink: 'Del',
        serviceList: 'Ved din side',
        questions: 'SKRIV OS',
      },
    },
    cartFormShipping: {
      checkbox: {
        invoiceToCompany: 'ERHVERVSKUNDE',
        otherDeliveryInfo: 'ANDEN LEVERINGSADRESSE',
        otherDeliveryInfoAlert: 'Den afgiftspligtige kan ikke bruge en anden adresse til levering.',
      },
      companyNameLabel: 'Social grund',
      firstNameLabel: 'Fornavn',
      lastNameLabel: 'Efternavn',
      streetLabel: 'Gade',
      cityLabel: 'By',
      zipLabel: 'Postnummer',
      companyIDLabel: 'Skatteidentifikationsnummer',
      countryLabel: 'Land',
    },
    cartFormPersonal: {
      label: 'Leveringsadresser',
      firstNameLabel: 'Fornavn',
      lastNameLabel: 'Efternavn',
      streetLabel: 'Gade / husnummer',
      pscLabel: 'Postnummer',
      cityLabel: 'By',
      countryLabel: 'Land',
      phoneLabel: 'Telefonnummer',
      emailLabel: 'E-mail',
      passwordLabel: 'Adgangskode',
      controlPasswordLabel: 'kontrol adgangskode',
    },
    cartFormInvoice: {
      labelDesktop: 'KUNDEOPLYSNINGER',
      labelMobile: 'Kontaktoplysninger',
      companyLabel: 'Social grund',
      password: 'Adgangskode',
      confirmPassword: 'Adgangskodebekræftelse',
      email: 'E-mail',
      firstName: 'Fornavn',
      lastName: 'Efternavn',
      countryLabel: 'Land',
      phone: 'Telefonnummer',
      phoneError: 'Telefonnummeret er ikke korrekt',
      streetLabel: 'Gade',
      streetNumberLabel: 'Husnummer',
      zipLabel: 'Postnummer',
      icoLabel: 'Ingen',
      dicLabel: 'Skatteidentifikationsnummer',
      country: 'Land',
      cityLabel: 'By',
      registration: 'Opret en kundekonto',
      registrationNote: 'Ny kunde ?',
      button: 'Gem ændringer',
    },
    page404: {
      title: 'Desværre',
      subTitle: 'Tilbage til hjemmesiden.',
      button: 'Tilbage til hjemmesiden.',
    },
    productListOrderSelectorButton: {
      selectLabel: 'Sortér efter',
      byName: 'Alfabetisk',
      byPrice: 'Pris',
      onlyStock: 'På lager',
      resetFilter: 'NULSTIL',
      displayCountOfProducts: 'viser',
      search: 'Søge',
      clear: 'At slette',
      ok: 'SORTER',
    },
    priceRangeSliderField: {
      title: 'Prisskala',
    },
    productListMultipleFilterMobileDialog: {
      headerTitle: 'Sortér efter',
    },
    productListMultipleFilterMobile: {
      title: 'FILTERET',
    },
    cartCompany: {
      companyName: 'Firmanavn',
      regNo: 'CVR-nummer',
      taxNo: 'Momsnummer',
      taxNoPlaceholder: 'DK00000000000',
      warningMessage: {
        warning: 'Vigtig.',
        message: 'Ved levering til en anden adresse end virksomhedens',
      },
    },
    searchResultsPage: {
      match: 'Vi fandt {resultCountFormatted} resultater for {searchText}.',
      nomatch: 'Der blev ikke fundet nogen resultater for {searchText}.',
      matchingCategories: 'Kategorier fundet',
      button: 'Tilbage til hjemmesiden.',
    },
    selectMultipleFilter: {
      totalChipsLabel: 'Udvalgte {total} produkter',
    },
    cartDelivery: {
      firstName: 'Fornavn',
      lastName: 'Efternavn',
      companyName: 'Social grund',
      streetLabel: 'Gade',
      streetNumberLabel: 'Husnummer',
      zipLabel: 'Postnummer',
      country: 'Land',
      cityLabel: 'By',
    },
    cartNotes: {
      shippingLabel: 'Tilføj leveringsinstruktioner',
      shippingNote: 'Leveringsinstruktioner (valgfrit)',
      sellerLabel: 'Tilføj en note til sælger',
      sellerNote: 'Notat (valgfrit)',
      loginNote: 'Log ind  eller fortsæt herunder',
    },
    cartCountryShipping: {
      title: 'VÆLG ET ANDET LAND',
      label: 'Valget af land påvirker betalingsmetoden og leveringen',
    },
    cartAddress: {
      bllingAddress: 'Faktura leveringsadresse',
      deliveryAddress: 'Leveringsadresse',
      pickUpPointAddress: 'Afhentningsstedsadresse',
    },
    cartAgreements: {
      customerVerified:
        'Jeg er ikke enig i udsendelsen af spørgeskemaet, som er en del af programmet " Verificerede anmeldelser på PriceRunner", som udføres med det formål at måle kundetilfredshed og med det formål at forbedre kvaliteten af vores ydelser.',
    },
    loginDialog: {
      username: 'E-mail',
      password: 'Adgangskode',
    },
    sprinxFormsValidation: {
      confirmPassword: 'De indtastede adgangskoder er ikke de samme',
      invalidPostalCode: 'Postnummer er ikke i et gyldigt format',
      usernameIsNotFree: 'En bruger med dette navn er allerede registreret.',
      emailEmpty: 'Korrekt e-mail!',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Tilføj en ny konto',
        all: 'Alle konti',
      },
      listPage: {
        table: {
          columns: {
            downloadLink: 'PDF',
            number: 'Nummer',
            invoiceDate: 'Dato',
            dueDate: 'På datoen',
            total: 'Total ekskl. moms',
            totalWithTax: 'Total pris',
            billingAddress: 'Faktura leveringsadresse',
            orderNumber: 'Ordrenummer',
            unpaidAmount: 'Udestående beløb',
          },
          filter: {
            number: 'Nummer',
          },
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Min konto',
          columns: {
            priceUnit: 'Pris per stk',
          },
        },
      },
    },
    cartSummaryTotal: {
      totalPrice: '',
      totalPriceWithTax: 'Varekøb total',
      tax: 'Moms',
      withoutTax: 'Total ekskl. moms',
    },
    compare: {
      addToCompare: 'Produktet er blevet tilføjet til komparatoren',
      removeToCompare: 'Produktet er blevet fjernet fra komparatoren',
      price: 'Pris',
      ean: 'EAN',
    },
    favorite: {
      addToFavorite: 'Produktet er blevet tilføjet til ønskeliste',
      removeFromFavorite: 'Produktet er blevet fjernet fra ønskeliste',
    },
    registration: {
      important: 'VIGTIG!',
      note: 'Vi sender dig en engangsadgangskode via e-mail. Når du er logget ind',
      gdprAgreement: 'Accepter venligst behandlingen af ​​personoplysninger',
      submitButton: 'Opret en konto',
      fail: 'Der er opstået en fejl',
      success: 'Forbindelsen lykkedes',
      title: 'OPRET KONTO',
      dialog: 'Endnu ikke medlem? Bliv medlem',
      here: 'her',
    },
    appBarActionGlobalSearchComposed: {
      label: 'Søg efter produkter',
    },
    appBarActions: {
      about: 'Kundeservice',
      aboutHref: '/alt-om-koeb',
      compare: 'Sammenlign',
    },
    appBarActionUser: {
      title: 'Log ind',
    },
    footer: {
      copyright: 'Copyright © Český velkoobchod s.r.o. alle rettigheder forbeholdt',
    },
    appArticlesPage: {
      title: 'genstande',
    },
    errorPage: {
      title: 'Noget er galt.',
      button: 'Hjemmeside',
    },
    cartSummary: {
      withoutTax: 'uden moms',
    },
    renewPasswordForm: {
      success: 'En ny adgangskode er blevet sendt til din e-mailadresse.',
      emailNotFound: 'E-mail ikke fundet!',
      error: 'Noget er galt',
    },
    cartSubjectListItemQuantityWarning: 'Maksimal ordremængde er {count} produkt(er)',
  },
};
export default messages;
