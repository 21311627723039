const messages = {
  da: {
    orders: {
      actions: {
        addNewOrder: 'Ny ordre',
        all: 'Alle ordrer',
      },
      listPage: {
        title: 'Ordres',
        table: {
          filter: {
            number: 'Nummer',
          },
          columns: {
            contact: 'Kontakt',
            dateOfOrder: 'Dato',
            number: 'Nummer',
            offer: 'Tilbud',
            opportunity: 'Lejlighed',
            owner: 'Ejeren',
            state: 'Status',
            total: 'Total ekskl. moms',
            totalUserCurrency: 'I alt {currency}',
          },
        },
      },
      order: {
        newEntity: 'Ny ordre',
        contact: {
          label: 'Kontakt',
          helperText: '',
          helperTextPreview: '',
        },
        commissionTotal: {
          label: 'Samlet kommission',
          helperText: '',
          helperTextPreview: '',
        },
        currency: {
          label: 'betalingsmiddel',
          helperText: '',
          helperTextPreview: '',
        },
        dateOfOrder: {
          label: 'Bestillingsdato',
          helperText: '',
          helperTextPreview: '',
        },
        number: {
          label: 'Nummer',
          helperText: 'Lad stå tomt for automatisk udfyldelse.',
          helperTextPreview: '',
        },
        offer: {
          label: 'At tilbyde',
          helperText: '',
          helperTextPreview: '',
        },
        opportunity: {
          label: 'Lejlighed',
          helperText: '',
          helperTextPreview: '',
        },
        owner: {
          label: 'Ejeren',
          helperText: '',
          helperTextPreview: '',
        },
        salesMargin: {
          label: 'Kommerciel margin',
          helperText: '',
          helperTextPreview: '',
        },
        subjects: {
          title: 'Emne',
          discount: {
            label: 'Levering',
            helperText: '',
            helperTextPreview: '',
          },
          commission: {
            label: 'Provision',
            helperText: '',
            helperTextPreview: '',
          },
          list: {
            package: 'pakke størrelse',
          },
          listPrice: {
            label: 'Priskatalog',
            helperText: '',
            helperTextPreview: '',
          },
          price: {
            label: 'Pris',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            label: 'Produkt',
            helperText: '',
            helperTextPreview: '',
          },
          quantity: {
            label: 'Antal',
            helperText: '',
            helperTextPreview: '',
          },
          salesMargin: {
            label: 'Kommerciel margin',
            helperText: '',
            helperTextPreview: '',
          },
          subjectTotal: {
            label: 'Total',
            helperText: '',
            helperTextPreview: '',
          },
          unit: {
            label: 'Enhed',
            helperText: '',
            helperTextPreview: '',
          },
        },
        total: {
          label: 'Total',
          helperText: '',
          helperTextPreview: '',
        },
        numberOfSubjects:
          '{number, plural, =0 {intet produkt} one {1 produkt} few {{number} produkter} other {{number} produkter}}',
      },
      myOrdersScoringBlog: {
        title: 'Mine Ordrer',
      },
      ordersList: 'Bestille',
    },
  },
};
export default messages;
